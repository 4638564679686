import MDEditor, { commands, type GroupOptions } from '@uiw/react-md-editor'
import cn from 'classnames'
import { type FormikProps } from 'formik'
import { type ComponentProps } from 'react'
import { z } from 'zod'
import { useForm } from '../../lib/form'
import { Alert } from '../Alert'
import { FormItems } from '../FormItems'
import { UploadToS3 } from '../UploadToS3'
import css from './index.module.scss'

const SubChildren = (props: ComponentProps<NonNullable<GroupOptions['children']>>) => {
  const { close, getState, textApi, dispatch } = props
  const { formik, alertProps } = useForm({
    initialValues: {
      uploadUrl: '',
    },
    validationSchema: z.object({
      uploadUrl: z.string(),
    }),
    onSubmit: (values) => {
      if (textApi) {
        textApi.replaceSelection(`![](${values.uploadUrl})`)
      }
      close()
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'News Post updated successfully',
  })
  if (!textApi || !getState || !dispatch) {
    return null
  }
  return (
    <div style={{ padding: 10 }}>
      <FormItems>
        <Alert {...alertProps} />
        <UploadToS3
          hideValue
          name="uploadUrl"
          formik={formik}
          accept="image/*"
          onUpload={() => {
            formik.handleSubmit()
          }}
        />
      </FormItems>
    </div>
  )
}

export const RichEditor = ({ name, label, formik }: { name: string; label: string; formik: FormikProps<any> }) => {
  const value = formik.values[name]
  const error = formik.errors[name] as string | undefined
  const touched = formik.touched[name]
  const invalid = !!touched && !!error
  const disabled = formik.isSubmitting

  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      <label className={css.label} htmlFor={name}>
        {label}
      </label>

      <MDEditor
        className={cn({
          // [css.input]: true,
          // [css.invalid]: invalid,
        })}
        commands={[
          commands.bold,
          commands.italic,
          commands.hr,
          commands.divider,
          commands.link,
          commands.code,
          commands.quote,
          commands.image,
          commands.unorderedListCommand,
          commands.orderedListCommand,
          commands.group([], {
            name: 'update',
            groupName: 'update',
            icon: (
              <svg viewBox="0 0 1024 1024" width="12" height="12">
                <path
                  fill="currentColor"
                  d="M716.8 921.6a51.2 51.2 0 1 1 0 102.4H307.2a51.2 51.2 0 1 1 0-102.4h409.6zM475.8016 382.1568a51.2 51.2 0 0 1 72.3968 0l144.8448 144.8448a51.2 51.2 0 0 1-72.448 72.3968L563.2 541.952V768a51.2 51.2 0 0 1-45.2096 50.8416L512 819.2a51.2 51.2 0 0 1-51.2-51.2v-226.048l-57.3952 57.4464a51.2 51.2 0 0 1-67.584 4.2496l-4.864-4.2496a51.2 51.2 0 0 1 0-72.3968zM512 0c138.6496 0 253.4912 102.144 277.1456 236.288l10.752 0.3072C924.928 242.688 1024 348.0576 1024 476.5696 1024 608.9728 918.8352 716.8 788.48 716.8a51.2 51.2 0 1 1 0-102.4l8.3968-0.256C866.2016 609.6384 921.6 550.0416 921.6 476.5696c0-76.4416-59.904-137.8816-133.12-137.8816h-97.28v-51.2C691.2 184.9856 610.6624 102.4 512 102.4S332.8 184.9856 332.8 287.488v51.2H235.52c-73.216 0-133.12 61.44-133.12 137.8816C102.4 552.96 162.304 614.4 235.52 614.4l5.9904 0.3584A51.2 51.2 0 0 1 235.52 716.8C105.1648 716.8 0 608.9728 0 476.5696c0-132.1984 104.8064-239.872 234.8544-240.2816C258.5088 102.144 373.3504 0 512 0z"
                />
              </svg>
            ),
            children: (props) => <SubChildren {...props} />,
            // execute: (state, api) => {
            //   console.log('>>>>>>update>>>>>', state)
            //   // api.replaceSelection(value)
            // },
            buttonProps: { 'aria-label': 'Insert title' },
          }),
        ]}
        value={value}
        onChange={(val) => {
          void formik.setFieldValue(name, val)
        }}
      />
      {invalid && <div className={css.error}>{error}</div>}
    </div>
  )
}
