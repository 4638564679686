import { zStringRequired } from '@chatai/shared/src/zod'
import { z } from 'zod'

export const zCreateAssistantTrpcInput = z.object({
  hidden: z.boolean(),
  name: zStringRequired,
  description: zStringRequired,
  bodyMd: zStringRequired,
  imageUrl: zStringRequired,
  systemPrompt: zStringRequired,
  responseText: zStringRequired,
  chatTitle: zStringRequired,
})
