import cn from 'classnames'
import { type FormikProps } from 'formik'
import get from 'lodash/get'
import css from './index.module.scss'

export const Textarea = ({
  name,
  hint,
  label,
  formik,
  initialHeight = 180,
  maxWidth,
}: {
  name: string
  label: string
  hint?: React.ReactNode
  formik: FormikProps<any>
  initialHeight?: number
  maxWidth?: string | number
}) => {
  const value = get(formik.values, name)
  const error = get(formik.errors, name) as string | undefined
  const touched = get(formik.touched, name) as boolean | undefined
  const invalid = !!touched && !!error
  const disabled = formik.isSubmitting

  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      <label className={css.label} htmlFor={name}>
        {label}
      </label>
      <textarea
        style={{ height: initialHeight, maxWidth }}
        className={cn({
          [css.input]: true,
          [css.invalid]: invalid,
        })}
        onChange={(e) => {
          void formik.setFieldValue(name, e.target.value)
        }}
        onBlur={() => {
          void formik.setFieldTouched(name)
        }}
        value={value}
        name={name}
        id={name}
        disabled={formik.isSubmitting}
      />
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
