import { zCreatePushCampaignTrpcInput } from '@chatai/admin-backend/src/router/pushCampaigns/createPushCampaign/input'
import { canSendPushes } from '@chatai/admin-backend/src/utils/can'
import { omit } from '@chatai/shared/src/omit'
import {
  usersGroupsOsLabelsSelectOptions,
  usersGroupsSubscriptionLabelsSelectOptions,
  zUserGroupOsLabel,
  zUserGroupSubscriptionLabel,
  type UserGroupLabel,
} from '@chatai/shared/src/usersGroups'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RadioButtons } from '../../../components/RadioButtons'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getPushCampaignRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const PushCampaignNewPage = withPageWrapper({
  checkAccess: ({ ctx }) => canSendPushes(ctx.me),
  title: 'New Push Campaign',
})(() => {
  const navigate = useNavigate()
  const createPushCampaign = trpc.createPushCampaign.useMutation()
  // const trpcUtils = trpc.useContext()
  const nowRef = useRef(new Date())
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      plannedAt: nowRef.current,
      shouldRespectTimezone: false,
      usersGroupOs: 'allOs',
      usersGroupSubscription: 'allSubscriptions',
      title: '',
      body: '',
      path: '',
    },
    validationSchema: zCreatePushCampaignTrpcInput
      .omit({
        usersGroups: true,
      })
      .extend({
        usersGroupOs: zUserGroupOsLabel,
        usersGroupSubscription: zUserGroupSubscriptionLabel,
      }),
    onSubmit: async (values) => {
      const usersGroups: UserGroupLabel[] = [values.usersGroupOs, values.usersGroupSubscription]
      const input = {
        ...omit(values, ['usersGroupOs', 'usersGroupSubscription']),
        usersGroups,
      }
      const result = await createPushCampaign.mutateAsync(input)
      navigate(getPushCampaignRoute({ pushCampaignSerialNumber: result.pushCampaign.serialNumber.toString() }))
    },
    resetOnSuccess: true,
    enableReinitialize: true,
  })
  return (
    <Segment title="New Push Campaign">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Planned At" name="plannedAt" type="datetime-local" formik={formik} />
          <Checkbox label="Should Respect Timezone" name="shouldRespectTimezone" formik={formik} />
          <RadioButtons
            label="User Group OS"
            name="usersGroupOs"
            options={usersGroupsOsLabelsSelectOptions}
            formik={formik}
          />
          <RadioButtons
            label="User Group Subscription"
            name="usersGroupSubscription"
            options={usersGroupsSubscriptionLabelsSelectOptions}
            formik={formik}
          />
          <Input label="Title" name="title" formik={formik} />
          <Input label="Body" name="body" formik={formik} />
          <Input label="Path" name="path" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create Push Campaign</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
