import type { TrpcRouterOutput } from '@chatai/admin-backend/src/router'
import { zGetAssistantsTrpcInput } from '@chatai/admin-backend/src/router/assistants/getAssistants/input'
import { canManageAssistants } from '@chatai/admin-backend/src/utils/can'
import update from 'immutability-helper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Link } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import { Alert } from '../../../components/Alert'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditAssistantRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

type MoveCard = (dragIndex: number, hoverIndex: number) => any

const Assistant = ({
  assistant,
  index,
  moveCard,
}: {
  assistant: TrpcRouterOutput['getAssistants']['assistants'][number]
  index: number
  moveCard: MoveCard
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop({
    accept: 'assistant',
    hover(item: { index: number }) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // console.log(dragIndex, hoverIndex, item)
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      // const hoverBoundingRect = ref.current.getBoundingClientRect()
      // // Get vertical middle
      // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // // Determine mouse position
      // const clientOffset = monitor.getClientOffset() || { x: 0, y: 0 }
      // // Get pixels to the top
      // const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // // Only perform the move when the mouse has crossed half of the items height
      // // When dragging downwards, only move when the cursor is below 50%
      // // When dragging upwards, only move when the cursor is above 50%
      // // Dragging downwards
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return
      // }
      // // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return
      // }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'assistant',
    item: { type: 'assistant', index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0.2 : 1
  drag(drop(ref))
  return (
    <div ref={ref} className={css.assistant} style={{ opacity }}>
      <Link className={css.assistantLink} to={getEditAssistantRoute({ assistantId: assistant.data.id })}>
        {assistant.data.name}
      </Link>
      <br />
      <p className={css.assistantDesc}>
        Last 7 days users: {assistant.usersCount}, usages: {assistant.usagesCount}
      </p>
    </div>
  )
}

const validationSchema = zGetAssistantsTrpcInput.pick({ dateFrom: true, dateTo: true, buildVersion: true })
export const AssistantsPage = withPageWrapper({
  title: 'Assistants',
  checkAccess: ({ ctx }) => canManageAssistants(ctx.me),
})(() => {
  const filterForm = useForm({
    initialValues: { dateFrom: '', dateTo: '', buildVersion: '' },
    validationSchema,
  })
  const filter = (() => {
    const maybeFilter = {
      dateFrom: filterForm.formik.values.dateFrom,
      dateTo: filterForm.formik.values.dateTo,
      buildVersion: filterForm.formik.values.buildVersion,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return parseResult.success ? maybeFilter : {}
  })()
  const qr = trpc.getAssistants.useQuery(filter)
  const [assistants, setAssistants] = useState<TrpcRouterOutput['getAssistants']['assistants']>([])
  useEffect(() => {
    setAssistants(qr.data?.assistants || [])
    setCards(qr.data?.assistants.map((assistant) => ({ assistant })) || [])
  }, [JSON.stringify(qr)])

  const updateAssistantsOrder = trpc.updateAssistantsOrder.useMutation()
  const [cards, setCards] = useState(assistants.map((assistant) => ({ assistant })))
  const moveCard = useCallback<MoveCard>(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      )
    },
    [cards]
  )
  const [prevIds, setPrevIds] = useState(assistants.map((assistant) => assistant.data.id))
  const newIds = useDebounce(
    cards.map((card) => card.assistant.data.id),
    1000
  )
  useEffect(() => {
    const isEqual = prevIds.toString() === newIds.toString()
    if (isEqual) {
      return
    }
    setPrevIds(newIds)
    void updateAssistantsOrder.mutateAsync({
      ids: newIds,
    })
  }, [prevIds, newIds, updateAssistantsOrder])
  return (
    <Segment title="Assistants">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={filterForm.formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={filterForm.formik} />
          <Input maxWidth={'100%'} label="Build Version" name="buildVersion" formik={filterForm.formik} />
        </FormItems>
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className={css.assistants}>
          {qr.isLoading ? (
            <Loader type="section" />
          ) : cards.length ? (
            cards.map((card, index) => (
              <Assistant index={index} moveCard={moveCard} assistant={card.assistant} key={card.assistant.data.id} />
            ))
          ) : (
            <Alert color="brown">There is no assistants</Alert>
          )}
        </div>
      </DndProvider>
    </Segment>
  )
})
