import { zUpdateImageAiProvidersInput } from '@chatai/admin-backend/src/router/appConfig/updateImageAiProviders/input'
import { canManageAppConfig } from '@chatai/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RadioButtons } from '../../../components/RadioButtons'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

export const AiImageProvidersConfigPage = withPageWrapper({
  authorizedOnly: true,
  useQuery: () => {
    return trpc.getAppConfig.useQuery()
  },
  setProps: ({ queryResult }) => ({
    appConfig: queryResult.data.appConfig,
  }),
  checkAccess: ({ ctx }) => canManageAppConfig(ctx.me),
  title: 'Ai Image Providers',
})(({ appConfig }) => {
  const updateImageAiProviders = trpc.updateImageAiProviders.useMutation()
  const trpcUtils = trpc.useContext()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      defaultFalModel: appConfig.defaultFalModel,
      defaultReplicateModel: appConfig.defaultReplicateModel,
      defaultStableDiffusionModel: appConfig.defaultStableDiffusionModel,
      defaultGenerateImageAIProvider: appConfig.defaultGenerateImageAIProvider,
    },
    validationSchema: zUpdateImageAiProvidersInput,
    onSubmit: async (values) => {
      const result = await updateImageAiProviders.mutateAsync(values)
      trpcUtils.getAppConfig.setData(undefined, result)
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Ai Image Providers updated successfully',
  })

  return (
    <Segment title="Ai Image Providers">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <RadioButtons
            label="Default AI Image Provider"
            name="defaultGenerateImageAIProvider"
            options={[
              { value: 'fal', label: 'Fal' },
              { value: 'replicate', label: 'Replicate' },
              { value: 'stableDiffusion', label: 'Stable Diffusion' },
              { value: 'openai', label: 'OpenAI' },
            ]}
            formik={formik}
          />
          <Input label="Default Fal Model" name="defaultFalModel" formik={formik} />
          <Input label="Default Replicate Model" name="defaultReplicateModel" formik={formik} />
          <Input label="Default Stable Diffusion Model" name="defaultStableDiffusionModel" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Update Ai Image Providers</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
