import { z } from 'zod'
import { endOfDay, isValidDate, parseDate, startOfDay, toUTC } from './date'

export const zEnvNonemptyTrimmed = z.string().trim().min(1)
export const zEnvNonemptyTrimmedRequiredOnNotLocal = zEnvNonemptyTrimmed.optional().refine(
  // eslint-disable-next-line node/no-process-env
  (val) => `${process.env.HOST_ENV}` === 'local' || !!val,
  'Required on not local host'
)
export const zEnvHost = z.enum(['local', 'development', 'production'])

export const zStringRequired = z.string({ required_error: 'Please, fill it' }).min(1, 'Please, fill it')
export const zStringOptional = z.string().optional()
export const zStringOptionalToEmpty = z.string().optional().default('')
export const zEmptyStringOptionalNullable = z.string().max(0).optional().nullable()
export const zEmailRequired = zStringRequired.email()
export const zNickRequired = zStringRequired.regex(
  /^[a-z0-9-]+$/,
  'Nick may contain only lowercase letters, numbers and dashes'
)
export const zStringMin = (min: number) => zStringRequired.min(min, `Text should be at least ${min} characters long`)
export const zPasswordsMustBeTheSame =
  (passwordFieldName: string, passwordAgainFieldName: string) => (val: any, ctx: z.RefinementCtx) => {
    if (val[passwordFieldName] !== val[passwordAgainFieldName]) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Passwords must be the same',
        path: [passwordAgainFieldName],
      })
    }
  }

const zStringDate = zStringRequired
  .regex(/^\d{2}\.\d{2}\.\d{4}$/, {
    message: 'Невалидная дата. Валдиная дата должна быть в формате ДД.ММ.ГГГГ',
  })
  .refine((value) => isValidDate(parseDate(value, 'dd.MM.yyyy', new Date())), {
    message: 'Невалидная дата. Валдиная дата должна быть в формате ДД.ММ.ГГГГ',
  })
export const zStringDateFromRequired = zStringDate.transform((value) =>
  toUTC(startOfDay(parseDate(value, 'dd.MM.yyyy', new Date())))
)
export const zStringDateFromOptonal = z.union([zStringDateFromRequired, zEmptyStringOptionalNullable])
export const zStringDateToRequired = zStringDate.transform((value) =>
  toUTC(endOfDay(parseDate(value, 'dd.MM.yyyy', new Date())))
)
export const zStringDateToOptonal = z.union([zStringDateToRequired, zEmptyStringOptionalNullable])
