import cn from 'classnames'
import { type FormikProps } from 'formik'
import css from './index.module.scss'

export const RadioButtons = ({
  name,
  label,
  options,
  formik,
}: {
  name: string
  label: string
  formik: FormikProps<any>
  options: Array<{ label: string; value: string }>
}) => {
  const value = formik.values[name] as string
  const error = formik.errors[name] as string | undefined
  const touched = formik.touched[name]
  const invalid = !!touched && !!error
  const disabled = formik.isSubmitting

  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      <label className={css.label}>{label}</label>
      <div className={css.radioButtons}>
        {options.map((option) => (
          <label className={css.radioButton} key={option.label}>
            <input
              className={css.radioButtonInput}
              type="radio"
              onChange={(e) => {
                const newValue = e.target.checked ? option.value : ''
                if (newValue) {
                  void formik.setFieldValue(name, newValue)
                }
              }}
              onBlur={() => {
                void formik.setFieldTouched(name)
              }}
              checked={value === option.value}
              name={name}
              id={name}
              disabled={formik.isSubmitting}
            />
            <span className={css.radioButtonLabel}>{option.label}</span>
          </label>
        ))}
      </div>
      {invalid && <div className={css.error}>{error}</div>}
    </div>
  )
}
