import { canManageAdmins, toHumanReadableRole } from '@chatai/admin-backend/src/utils/can'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditAdminRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const AdminsPage = withPageWrapper({
  title: 'News Posts',
  checkAccess: ({ ctx }) => canManageAdmins(ctx.me),
})(() => {
  const { data, error } = trpc.getAdmins.useQuery({})

  return (
    <Segment title="News Posts">
      {error ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data ? (
        <Loader type="section" />
      ) : !data.admins.length ? (
        <Alert color="brown">Nothing found</Alert>
      ) : (
        <div className={css.admins}>
          <table className={css.table}>
            <thead>
              <tr>
                <th>E-mail</th>
                <th>Name</th>
                <th>Role</th>
                <th>Banned</th>
              </tr>
            </thead>
            <tbody className={css.tbody}>
              {data.admins.map((admin) => (
                <tr className={css.admin} key={admin.id}>
                  <td>
                    <Link className={css.adminLink} to={getEditAdminRoute({ adminId: admin.id })}>
                      {admin.email}
                    </Link>
                  </td>
                  <td>{admin.name || '—'}</td>
                  <td>{toHumanReadableRole(admin.role)}</td>
                  <td>{admin.bannedAt ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Segment>
  )
})
