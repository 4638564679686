import { zUpdateNewsPostTrpcInput } from '@chatai/admin-backend/src/router/newsPosts/updateNewsPost/input'
import { canManageNewsPosts } from '@chatai/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button, Buttons } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RichEditor } from '../../../components/RichEditor'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { UploadToS3 } from '../../../components/UploadToS3'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditNewsPostRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const NewsPostEditPage = withPageWrapper({
  useQuery: () => {
    const { newsPostId } = getEditNewsPostRoute.useParams()
    return trpc.getNewsPost.useQuery({
      id: newsPostId,
    })
  },
  setProps: ({ queryResult }) => ({
    newsPost: queryResult.data.newsPost,
  }),
  checkAccess: ({ ctx }) => canManageNewsPosts(ctx.me),
  showLoaderOnFetching: false,
  title: ({ newsPost }) => `News Post: ${newsPost.title}`,
})(({ newsPost }) => {
  const updateNewsPost = trpc.updateNewsPost.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      // title: newsPost.title,
      // body: newsPost.body,
      // buildNumber: newsPost.buildNumber.toString() as any,
      // imageUrl: newsPost.imageUrl,
      chatEnabled: newsPost.chatEnabled,
      publishedAt: newsPost.publishedAt,
      title: newsPost.title,
      bodyMd: newsPost.bodyMd,
      imageUrl: newsPost.imageUrl,
      initialText: newsPost.initialText,
      responseText: newsPost.responseText,
      chatTitle: newsPost.chatTitle,
      buttonText: newsPost.buttonText,
    },
    validationSchema: zUpdateNewsPostTrpcInput.shape.data,
    onSubmit: async (values) => {
      await updateNewsPost.mutateAsync({
        id: newsPost.id,
        data: values,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'News Post updated successfully',
  })

  const deleteNewsPost = trpc.deleteNewsPost.useMutation()
  const deleteForm = useForm({
    onSubmit: async () => {
      await deleteNewsPost.mutateAsync({
        id: newsPost.id,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'News Post deleted successfully',
  })
  return (
    <Segment title={`News Post: ${newsPost.title}`} description={newsPost.id}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Published At" name="publishedAt" type="datetime-local" formik={formik} />
          <Input label="Title" name="title" formik={formik} />
          <RichEditor label="Body" name="bodyMd" formik={formik} />
          <UploadToS3 label="Image" name="imageUrl" formik={formik} accept="image/*" />
          <Checkbox label="Chat Enabled" name="chatEnabled" formik={formik} />
          {formik.values.chatEnabled && (
            <>
              <Textarea
                label="Inital Message Text"
                hint="Start of invisible message from user on chat start, it will be combined with body text"
                name="initialText"
                formik={formik}
              />
              <Textarea
                label="Response Message Text"
                hint="Visible response message from assistant"
                name="responseText"
                formik={formik}
              />
              <Input label="Chat Title" hint="Chat title for new chat" name="chatTitle" formik={formik} />
              <Input label="Button Text" hint="Text on button to start a chat" name="buttonText" formik={formik} />
            </>
          )}
          <Alert {...alertProps} />
          <Alert {...deleteForm.alertProps} />
          <Buttons>
            <Button {...buttonProps}>Update News Post</Button>
            <Button
              {...deleteForm.buttonProps}
              color="red"
              type="button"
              onClick={() => {
                deleteForm.formik.handleSubmit()
              }}
            >
              Delete
            </Button>
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
