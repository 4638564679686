import cn from 'classnames'
import { type FormikProps } from 'formik'
import css from './index.module.scss'

export const Checkbox = ({
  name,
  label,
  formik,
}: {
  name: string
  label: React.ReactNode
  formik: FormikProps<any>
}) => {
  const value = formik.values[name]
  const error = formik.errors[name] as string | undefined
  const touched = formik.touched[name]
  const invalid = !!touched && !!error
  const disabled = formik.isSubmitting

  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      <label className={css.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={cn({
          [css.input]: true,
          [css.invalid]: invalid,
        })}
        type="checkbox"
        onChange={(e) => {
          void formik.setFieldValue(name, e.target.checked)
        }}
        onBlur={() => {
          void formik.setFieldTouched(name)
        }}
        checked={value}
        name={name}
        id={name}
        disabled={formik.isSubmitting}
      />
      {invalid && <div className={css.error}>{error}</div>}
    </div>
  )
}
