import { zStringOptionalToEmpty, zStringRequired } from '@chatai/shared/src/zod'
import { z } from 'zod'

const zNewsPostGeneralInput = z.object({
  publishedAt: z.coerce.date(),
  title: zStringRequired,
  bodyMd: zStringRequired,
  imageUrl: zStringRequired,
})

const zNewsPostWithoutChat = zNewsPostGeneralInput.extend({
  chatEnabled: z.literal(false),
  initialText: zStringOptionalToEmpty,
  responseText: zStringOptionalToEmpty,
  chatTitle: zStringOptionalToEmpty,
  buttonText: zStringOptionalToEmpty,
})

const zNewsPostWithChat = zNewsPostGeneralInput.extend({
  chatEnabled: z.literal(true),
  initialText: zStringRequired,
  responseText: zStringRequired,
  chatTitle: zStringRequired,
  buttonText: zStringRequired,
})

export const zNewsPostInput = z.union([zNewsPostWithoutChat, zNewsPostWithChat])
