import { canManageAutoCampaigns } from '@chatai/admin-backend/src/utils/can'
import { autoCampaignsConfigFromObjectToText } from '@chatai/shared/src/autoCampaignsConfig'
import { z } from 'zod'
import { Alert } from '../../../components/Alert'
import { Button, Buttons } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

export const AutoCampaignsEditPage = withPageWrapper({
  useQuery: () => {
    return trpc.getAppConfig.useQuery()
  },
  setProps: ({ queryResult }) => ({
    appConfig: queryResult.data.appConfig,
  }),
  checkAccess: ({ ctx }) => canManageAutoCampaigns(ctx.me),
  showLoaderOnFetching: false,
  title: () => `Auto Campaigns Config`,
})(({ appConfig }) => {
  const updateAutoCampaignsConfig = trpc.updateAutoCampaignsConfig.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      autoCampaignsConfig: autoCampaignsConfigFromObjectToText(appConfig.autoCampaignsConfig),
    },
    validationSchema: z.object({
      autoCampaignsConfig: z.string(),
    }),
    onSubmit: async (values) => {
      await updateAutoCampaignsConfig.mutateAsync({
        autoCampaignsConfig: values.autoCampaignsConfig,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Auto campaigns config updated successfully',
  })

  return (
    <Segment title={`Auto Campaigns Config`}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Textarea initialHeight={400} label="Auto Campaigns Config" name="autoCampaignsConfig" formik={formik} />
          <Alert {...alertProps} />
          <Buttons>
            <Button {...buttonProps}>Update Config</Button>
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
