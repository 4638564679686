import { zGetPromptSuggestionsStatsTrpcInput } from '@chatai/admin-backend/src/router/promptSuggestions/getPromptSuggestionsStats/input'
import { canManageAutoCampaigns } from '@chatai/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetPromptSuggestionsStatsTrpcInput.pick({ dateFrom: true, dateTo: true, buildVersion: true })
export const AutoCampaignsPage = withPageWrapper({
  title: 'Auto Campaigns',
  checkAccess: ({ ctx }) => canManageAutoCampaigns(ctx.me),
})(() => {
  const filterForm = useForm({
    initialValues: { dateFrom: '', dateTo: '' },
    validationSchema,
  })
  const filter = (() => {
    const maybeFilter = {
      dateFrom: filterForm.formik.values.dateFrom,
      dateTo: filterForm.formik.values.dateTo,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return parseResult.success ? maybeFilter : {}
  })()
  const qr = trpc.getAutoCampaignsStats.useQuery(filter)

  return (
    <Segment title="Auto Campaigns">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={filterForm.formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={filterForm.formik} />
        </FormItems>
      </div>
      {(() => {
        if (qr.error) {
          return <Alert color="red">{qr.error.message}</Alert>
        }
        if (!qr.data) {
          return <Loader type="section" />
        }
        const autoCampaignsStats = qr.data.autoCampaignsStats
        return (
          <table className={css.table}>
            <thead>
              <tr>
                <th>Type</th>
                <th>Total</th>
                <th>Opened</th>
                <th>Conversion</th>
                <th>Title</th>
                <th>Body</th>
                <th>Path</th>
              </tr>
            </thead>
            <tbody>
              {autoCampaignsStats.map((acs, i) => (
                <tr key={i} className={acs.title === '' ? css.bold : undefined}>
                  <td>{acs.type}</td>
                  <td>{acs.total}</td>
                  <td>{acs.opened}</td>
                  <td>{acs.conversion.toFixed(2) + '%'}</td>
                  <td>{acs.title}</td>
                  <td>{acs.body}</td>
                  <td>{acs.path}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      })()}
    </Segment>
  )
})
