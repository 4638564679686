import cn from 'classnames'
import format from 'date-fns/format'
import { type FormikProps } from 'formik'
import get from 'lodash/get'
import css from './index.module.scss'

export const Input = ({
  name,
  label,
  formik,
  maxWidth,
  hint,
  type = 'text',
}: {
  name: string
  label: string
  formik: FormikProps<any>
  maxWidth?: number | string
  hint?: string
  type?: 'text' | 'password' | 'date' | 'datetime-local'
}) => {
  const value = get(formik.values, name)
  const error = get(formik.errors, name) as string | undefined
  const touched = get(formik.touched, name) as boolean | undefined
  const invalid = !!touched && !!error
  const disabled = formik.isSubmitting
  const currentInputValue = type === 'date' || type === 'datetime-local' ? format(value, "yyyy-MM-dd'T'HH:mm") : value

  return (
    <div className={cn({ [css.field]: true, [css.disabled]: disabled })}>
      <label className={css.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={cn({
          [css.input]: true,
          [css.invalid]: invalid,
        })}
        style={{ maxWidth }}
        type={type}
        onChange={(e) => {
          const newValue = type === 'date' || type === 'datetime-local' ? new Date(e.target.value) : e.target.value
          void formik.setFieldValue(name, newValue)
        }}
        onBlur={() => {
          void formik.setFieldTouched(name)
        }}
        value={currentInputValue}
        name={name}
        id={name}
        disabled={formik.isSubmitting}
      />
      {invalid && <div className={css.error}>{error}</div>}
      {hint && <div className={css.hint}>{hint}</div>}
    </div>
  )
}
