import { zUserGroupLabel } from '@chatai/shared/src/usersGroups'
import { z } from 'zod'

export const zCreatePushCampaignTrpcInput = z.object({
  shouldRespectTimezone: z.boolean(),
  plannedAt: z.coerce.date(),
  usersGroups: zUserGroupLabel.array(),
  title: z.string().min(1),
  body: z.string().min(1),
  path: z.string().min(1),
})
