import { zCreateUpgradePostTrpcInput } from '@chatai/admin-backend/src/router/upgradesPosts/createUpgradePost/input'
import { canManageUpgradesPosts } from '@chatai/admin-backend/src/utils/can'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { UploadToS3 } from '../../../components/UploadToS3'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUpgradesPostsRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const UpgradePostNewPage = withPageWrapper({
  checkAccess: ({ ctx }) => canManageUpgradesPosts(ctx.me),
  title: 'New Upgrade Post',
})(() => {
  const navigate = useNavigate()
  const createUpgradePost = trpc.createUpgradePost.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      title: '',
      body: '',
      buildNumber: '' as any,
      imageUrl: '',
    },
    validationSchema: zCreateUpgradePostTrpcInput,
    onSubmit: async (values) => {
      await createUpgradePost.mutateAsync(values)
      navigate(getUpgradesPostsRoute())
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Upgrade Post created successfully',
  })
  return (
    <Segment title="New Upgrade Post">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Build Number" name="buildNumber" formik={formik} />
          <Input label="Title" name="title" formik={formik} />
          <Textarea label="Body" name="body" formik={formik} />
          <UploadToS3 label="Image" name="imageUrl" formik={formik} accept="image/*" />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create Upgrade Post</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
