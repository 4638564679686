import { canAnalyzeChats } from '@chatai/admin-backend/src/utils/can'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getAnalyticCampaignRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const AnalyticCampaignPage = withPageWrapper({
  useQuery: () => {
    const { analyticCampaignSerialNumber } = getAnalyticCampaignRoute.useParams()
    return trpc.getAnalyticCampaign.useQuery({
      serialNumber: +analyticCampaignSerialNumber,
    })
  },
  setProps: ({ queryResult }) => ({
    analyticCampaign: queryResult.data.analyticCampaign,
  }),
  checkAccess: ({ ctx }) => canAnalyzeChats(ctx.me),
  showLoaderOnFetching: false,
  title: ({ analyticCampaign }) => `Analytic Campaign: ${analyticCampaign.serialNumber}`,
})(({ analyticCampaign }) => (
  <Segment title={`Analytic Campaign: ${analyticCampaign.serialNumber}`}>
    <Segment>
      <pre className={css.analyticCampaignInfo}>{JSON.stringify(analyticCampaign, null, 2)}</pre>
    </Segment>
  </Segment>
))
