import { zGetPushCampaignsTrpcInput } from '@chatai/admin-backend/src/router/pushCampaigns/getPushCampaigns/input'
import { canAnalyzePushes } from '@chatai/admin-backend/src/utils/can'
import format from 'date-fns/format'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import { Alert } from '../../../components/Alert'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getPushCampaignRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const PushCampaignsPage = withPageWrapper({
  title: 'Push Campaigns',
  checkAccess: ({ ctx }) => canAnalyzePushes(ctx.me),
})(() => {
  const { formik } = useForm({
    initialValues: { serialNumber: '' },
    validationSchema: zGetPushCampaignsTrpcInput.pick({ serialNumber: true }),
  })
  const serialNumber = useDebounce(formik.values.serialNumber, 500)
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getPushCampaigns.useInfiniteQuery(
      {
        serialNumber,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Push Campaigns">
      <div className={css.filter}>
        <Input maxWidth={'100%'} label="Serial Number" name="serialNumber" formik={formik} />
      </div>
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].pushCampaigns.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.pushCampaigns}>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!isFetchingNextPage && hasNextPage) {
                void fetchNextPage()
              }
            }}
            hasMore={hasNextPage}
            loader={
              <div className={css.more} key="loader">
                <Loader type="section" />
              </div>
            }
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {data.pages
              .flatMap((page) => page.pushCampaigns)
              .map((pushCampaign) => (
                <div className={css.pushCampaign} key={pushCampaign.serialNumber}>
                  <Segment
                    size={2}
                    title={
                      <Link
                        className={css.pushCampaignLink}
                        to={getPushCampaignRoute({ pushCampaignSerialNumber: pushCampaign.serialNumber.toString() })}
                      >
                        {pushCampaign.serialNumber}
                      </Link>
                    }
                  >
                    Status: {pushCampaign.status}
                    <br />
                    Date ({!pushCampaign.shouldRespectTimezone ? 'exact' : 'local'}):{' '}
                    {format(
                      !pushCampaign.shouldRespectTimezone
                        ? pushCampaign.plannedAt
                        : new Date(pushCampaign.plannedAt.getTime() + new Date().getTimezoneOffset() * 60000),
                      'yyyy-MM-dd HH:mm'
                    )}
                    <br />
                    Total Users Count: {pushCampaign.totalUsersCount}
                    <br />
                    Sent Users Count: {pushCampaign.sentUsersCount}
                    <br />
                    Skip Users Count: {pushCampaign.skipUsersCount}
                    <br />
                    Conversion: {(pushCampaign.conversion * 100).toFixed(2)}%
                  </Segment>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Segment>
  )
})
