import { zCreateAssistantTrpcInput } from '@chatai/admin-backend/src/router/assistants/createAssistant/input'
import { canManageAssistants } from '@chatai/admin-backend/src/utils/can'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RichEditor } from '../../../components/RichEditor'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { UploadToS3 } from '../../../components/UploadToS3'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getAssistantsRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const AssistantNewPage = withPageWrapper({
  checkAccess: ({ ctx }) => canManageAssistants(ctx.me),
  title: 'New Assistant',
})(() => {
  const navigate = useNavigate()
  const createAssistant = trpc.createAssistant.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      name: '',
      description: '',
      bodyMd: '',
      imageUrl: '',
      systemPrompt: '',
      hidden: false,
      responseText: '',
      chatTitle: '',
    },
    validationSchema: zCreateAssistantTrpcInput,
    onSubmit: async (values) => {
      await createAssistant.mutateAsync(values)
      navigate(getAssistantsRoute())
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Assistant created successfully',
  })
  return (
    <Segment title="New Assistant">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Name" name="name" formik={formik} />
          <Textarea label="Short Description For User" name="description" formik={formik} />
          <RichEditor label="Long Description For User" name="bodyMd" formik={formik} />
          <UploadToS3 label="Image" name="imageUrl" formik={formik} accept="image/*" />
          <Textarea label="System Prompt" name="systemPrompt" formik={formik} />
          <Textarea
            label="Response Message Text"
            hint="Visible response message from assistant"
            name="responseText"
            formik={formik}
          />
          <Input label="Chat Title" hint="Chat title for new chat" name="chatTitle" formik={formik} />
          <Checkbox label="Hidden" name="hidden" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create Assistant</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
