import { zStringDateFromOptonal, zStringDateToOptonal, zStringOptional } from '@chatai/shared/src/zod'
import { z } from 'zod'

export const zGetConversionsListTrpcInput = z.object({
  cursor: z.coerce.date().optional(),
  limit: z.number().min(1).max(100).default(10),
  search: zStringOptional,
  dateFrom: zStringDateFromOptonal,
  dateTo: zStringDateToOptonal,
})
