import { z } from 'zod'

export const usersGroupsCategories = ['os', 'subscription'] as const
export type UsersGroupsCategory = (typeof usersGroupsCategories)[number]
export const usersGroupsLabels = ['allOs', 'ios', 'android', 'allSubscriptions', 'subscribed', 'notSubscribed'] as const
export const usersGroupsOsLabels = ['allOs', 'ios', 'android'] as const
export const usersGroupsSubscriptionLabels = ['allSubscriptions', 'subscribed', 'notSubscribed'] as const
export type UserGroupLabel = (typeof usersGroupsLabels)[number]
export type UserGroupOsLabel = (typeof usersGroupsOsLabels)[number]
export type UserGroupSubscriptionLabel = (typeof usersGroupsSubscriptionLabels)[number]
export const zUserGroupLabel = z.enum(usersGroupsLabels)
export const zUserGroupOsLabel = z.enum(usersGroupsOsLabels)
export const zUserGroupSubscriptionLabel = z.enum(usersGroupsSubscriptionLabels)
export const toHumanUserGroupLabel = (label: UserGroupLabel) => {
  return {
    ios: 'iOS',
    android: 'Android',
    allOs: 'All OS',
    subscribed: 'Subscribed',
    notSubscribed: 'Not subscribed',
    allSubscriptions: 'All subscriptions',
  }[label]
}
export const usersGroupsLabelsSelectOptions = usersGroupsLabels.map((label) => ({
  label: toHumanUserGroupLabel(label),
  value: label,
}))
export const usersGroupsOsLabelsSelectOptions = usersGroupsOsLabels.map((label) => ({
  label: toHumanUserGroupLabel(label),
  value: label,
}))
export const usersGroupsSubscriptionLabelsSelectOptions = usersGroupsSubscriptionLabels.map((label) => ({
  label: toHumanUserGroupLabel(label),
  value: label,
}))
