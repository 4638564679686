import { zCreateAnalyticRequestTrpcInput } from '@chatai/admin-backend/src/router/analyticRequests/createAnalyticRequest/input'
import { canAnalyzeChats } from '@chatai/admin-backend/src/utils/can'
import { omit } from '@chatai/shared/src/omit'
import InfiniteScroll from 'react-infinite-scroller'
import { z } from 'zod'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const CreateRequestForm = () => {
  const trpcUtils = trpc.useUtils()
  const createAnalyticRequest = trpc.createAnalyticRequest.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      question: '',
      chatsCount: '',
    },
    validationSchema: zCreateAnalyticRequestTrpcInput
      .omit({
        chatsCount: true,
      })
      .extend({
        chatsCount: z.string().min(1),
      }),
    onSubmit: async (values) => {
      await createAnalyticRequest.mutateAsync({
        ...values,
        chatsCount: +values.chatsCount,
      })
      await trpcUtils.getAnalyticRequests.refetch()
    },
    resetOnSuccess: true,
    enableReinitialize: true,
  })
  return (
    <Segment title="New Analytic Request">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Chats Count" name="chatsCount" formik={formik} />
          <Textarea label="Question" name="question" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create Analytic Request</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

export const AnalyticRequestsPage = withPageWrapper({
  title: 'Analytic Requests',
  checkAccess: ({ ctx }) => canAnalyzeChats(ctx.me),
})(() => {
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getAnalyticRequests.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <>
      <CreateRequestForm />
      <Segment title="Analytic Requests">
        {isLoading || isRefetching ? (
          <Loader type="section" />
        ) : isError ? (
          <Alert color="red">{error.message}</Alert>
        ) : !data.pages[0].analyticRequests.length ? (
          <Alert color="brown">Nothing found by search</Alert>
        ) : (
          <div className={css.analyticRequests}>
            <InfiniteScroll
              threshold={250}
              loadMore={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  void fetchNextPage()
                }
              }}
              hasMore={hasNextPage}
              loader={
                <div className={css.more} key="loader">
                  <Loader type="section" />
                </div>
              }
              getScrollParent={() => layoutContentElRef.current}
              useWindow={
                (layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'
              }
            >
              {data.pages
                .flatMap((page) => page.analyticRequests)
                .map((analyticRequest) => (
                  <div className={css.analyticRequest} key={analyticRequest.serialNumber}>
                    <Segment size={2}>
                      <div className={css.question}>{analyticRequest.question}</div>
                      <div className={css.answer}>{analyticRequest.answer}</div>
                      <pre className={css.data}>
                        {JSON.stringify(omit(analyticRequest, ['question', 'answer']), null, 2)}
                      </pre>
                    </Segment>
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        )}
      </Segment>
    </>
  )
})
