import { zStringRequired } from '@chatai/shared/src/zod'
import { z } from 'zod'

export const zUpdateUpgradePostTrpcInput = z.object({
  id: z.string(),
  title: zStringRequired,
  body: zStringRequired,
  imageUrl: z.string().optional().nullable(),
  buildNumber: z.coerce.number().int().positive(),
})
