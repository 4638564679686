import { canViewAdminActionLogs } from '@chatai/admin-backend/src/utils/can'
import { format as formatDate } from 'date-fns'
import InfiniteScroll from 'react-infinite-scroller'
import { Alert } from '../../../components/Alert'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const AdminActionLogsPage = withPageWrapper({
  title: 'Admin Action Logs',
  checkAccess: ({ ctx }) => canViewAdminActionLogs(ctx.me),
})(() => {
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getAdminActionLogs.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Admin Action Logs">
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].adminActionLogs.length ? (
        <Alert color="brown">No Logs</Alert>
      ) : (
        <div className={css.logs}>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!isFetchingNextPage && hasNextPage) {
                void fetchNextPage()
              }
            }}
            hasMore={hasNextPage}
            loader={
              <div className={css.more} key="loader">
                <Loader type="section" />
              </div>
            }
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {data.pages
              .flatMap((page) => page.adminActionLogs)
              .map((log) => (
                <div className={css.log} key={log.serialNumber}>
                  <Segment
                    size={3}
                    title={`${formatDate(log.createdAt, 'yyyy-MM-dd HH:mm:ss')} — ${log.action} — ${log.admin.email}`}
                  >
                    <pre className={css.logData}>{JSON.stringify(log.data, null, 2)}</pre>
                  </Segment>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Segment>
  )
})
