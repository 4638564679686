import { pgr } from '../utils/pumpGetRoute'

export const getHomeRoute = pgr(() => '/')

export const getSignInRoute = pgr(() => '/sign-in')

export const getSignOutRoute = pgr(() => '/sign-out')

export const getEditProfileRoute = pgr(() => '/edit-profile')

export const getChatsRoute = pgr(() => `/chats`)

export const getUsersRoute = pgr(() => '/users')

export const getUserRoute = pgr({ userSerialNumber: true }, ({ userSerialNumber }) => `/users/${userSerialNumber}`)

export const getUserImagesRoute = pgr(
  { userSerialNumber: true },
  ({ userSerialNumber }) => `/users/${userSerialNumber}/images`
)

export const getUserChatsRoute = pgr(
  { userSerialNumber: true },
  ({ userSerialNumber }) => `/users/${userSerialNumber}/chats`
)

export const getUnsubscriptionsRoute = pgr(() => '/unsubscriptions')

export const getUnsubscriptionRoute = pgr(
  { unsubscriptionSerialNumber: true },
  ({ unsubscriptionSerialNumber }) => `/unsubscriptions/${unsubscriptionSerialNumber}`
)

export const getPushCampaignsRoute = pgr(() => '/push-campaigns')

export const getNewPushCampaignRoute = pgr(() => '/push-campaigns/new')

export const getPushCampaignRoute = pgr(
  { pushCampaignSerialNumber: true },
  ({ pushCampaignSerialNumber }) => `/push-campaigns/${pushCampaignSerialNumber}`
)

export const getAnalyticRequestsRoute = pgr(() => '/analytic-requests')

export const getAnalyticCampaignsRoute = pgr(() => '/analytic-campaigns')

export const getNewAnalyticCampaignRoute = pgr(() => '/analytic-campaigns/new')

export const getAnalyticCampaignRoute = pgr(
  { analyticCampaignSerialNumber: true },
  ({ analyticCampaignSerialNumber }) => `/analytic-campaigns/${analyticCampaignSerialNumber}`
)

export const getAppConfigRoute = pgr(() => '/app-config')

export const getConversionPostbackConfigsRoute = pgr(() => '/conversion-postback-configs')

export const getConversionPostbackLogsRoute = pgr(() => '/conversion-postback-logs')

export const getAiImageProvidersConfigRoute = pgr(() => '/ai-image-providers-config')

export const getAdminActionLogsRoute = pgr(() => '/admin-action-logs')

export const getPromptSuggestionsRoute = pgr(() => '/suggestions')

export const getUpgradesPostsRoute = pgr(() => '/upgrades-posts')

export const getNewUpgradePostRoute = pgr(() => '/upgrades-posts/new')

export const getEditUpgradePostRoute = pgr(
  { upgradePostId: true },
  ({ upgradePostId }) => `/upgrades-posts/${upgradePostId}/edit`
)

export const getNewsPostsRoute = pgr(() => '/news-posts')

export const getNewNewsPostRoute = pgr(() => '/news-posts/new')

export const getEditNewsPostRoute = pgr({ newsPostId: true }, ({ newsPostId }) => `/news-posts/${newsPostId}/edit`)

export const getAdminsRoute = pgr(() => '/admins')

export const getNewAdminRoute = pgr(() => '/admins/new')

export const getEditAdminRoute = pgr({ adminId: true }, ({ adminId }) => `/admins/${adminId}/edit`)

export const getAutoCampaignsRoute = pgr(() => '/auto-campaigns')

export const getEditAutoCampaignsRoute = pgr(() => '/auto-campaigns/edit')

export const getAssistantsRoute = pgr(() => '/assistants')

export const getNewAssistantRoute = pgr(() => '/assistants/new')

export const getEditAssistantRoute = pgr({ assistantId: true }, ({ assistantId }) => `/assistants/${assistantId}/edit`)

export const getClearAdaptyRoute = pgr(() => '/adapty-cleaning')

export const getClearUsersRoute = pgr(() => '/users-cleaning')

export const getConversionsListRoute = pgr(() => '/coversions/list')

export const getConversionsStatsRoute = pgr(() => '/coversions/stats')

export const getConversionsStatsWebRoute = pgr(() => '/coversions/stats-web')

export const getRetentionRoute = pgr(() => '/coversions/retention')

export const getTicketsRoute = pgr(() => '/tickets')

export const getTicketRoute = pgr(
  { ticketSerialNumber: true },
  ({ ticketSerialNumber }) => `/tickets/${ticketSerialNumber}`
)
