import { zCreateNewsPostTrpcInput } from '@chatai/admin-backend/src/router/newsPosts/createNewsPost/input'
import { canManageNewsPosts } from '@chatai/admin-backend/src/utils/can'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RichEditor } from '../../../components/RichEditor'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { UploadToS3 } from '../../../components/UploadToS3'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getNewsPostsRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const NewsPostNewPage = withPageWrapper({
  checkAccess: ({ ctx }) => canManageNewsPosts(ctx.me),
  title: 'New News Post',
})(() => {
  const navigate = useNavigate()
  const createNewsPost = trpc.createNewsPost.useMutation()
  const nowRef = useRef(new Date())
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      chatEnabled: false,
      publishedAt: nowRef.current,
      title: '',
      bodyMd: '',
      imageUrl: '',
      initialText: '',
      responseText: '',
      chatTitle: '',
      buttonText: '',
    },
    validationSchema: zCreateNewsPostTrpcInput,
    onSubmit: async (values) => {
      await createNewsPost.mutateAsync(values)
      navigate(getNewsPostsRoute())
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'News Post created successfully',
  })
  return (
    <Segment title="New News Post">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Published At" name="publishedAt" type="datetime-local" formik={formik} />
          <Input label="Title" name="title" formik={formik} />
          <RichEditor label="Body" name="bodyMd" formik={formik} />
          <UploadToS3 label="Image" name="imageUrl" formik={formik} accept="image/*" />
          <Checkbox label="Chat Enabled" name="chatEnabled" formik={formik} />
          {formik.values.chatEnabled && (
            <>
              <Textarea
                label="Inital Message Text"
                hint="Start of invisible message from user on chat start, it will be combined with body text"
                name="initialText"
                formik={formik}
              />
              <Textarea
                label="Response Message Text"
                hint="Visible response message from assistant"
                name="responseText"
                formik={formik}
              />
              <Input label="Chat Title" hint="Chat title for new chat" name="chatTitle" formik={formik} />
              <Input label="Button Text" hint="Text on button to start a chat" name="buttonText" formik={formik} />
            </>
          )}
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create News Post</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
