import { zUpdateAdminTrpcInput } from '@chatai/admin-backend/src/router/admins/updateAdmin/input'
import { availableRolesOptions, canManageAdmins } from '@chatai/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button, Buttons } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RadioButtons } from '../../../components/RadioButtons'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditAdminRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const AdminEditPage = withPageWrapper({
  useQuery: () => {
    const { adminId } = getEditAdminRoute.useParams()
    return trpc.getAdmin.useQuery({
      id: adminId,
    })
  },
  setProps: ({ queryResult }) => ({
    admin: queryResult.data.admin,
  }),
  checkAccess: ({ ctx }) => canManageAdmins(ctx.me),
  showLoaderOnFetching: false,
  title: ({ admin }) => `Admin: ${admin.name || admin.email}`,
})(({ admin }) => {
  const updateAdmin = trpc.updateAdmin.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      name: admin.name,
      role: admin.role as any,
    },
    validationSchema: zUpdateAdminTrpcInput.omit({ adminId: true }),
    onSubmit: async (values) => {
      await updateAdmin.mutateAsync({
        adminId: admin.id,
        ...values,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Admin updated successfully',
  })

  const banAdmin = trpc.banAdmin.useMutation()
  const banForm = useForm({
    onSubmit: async () => {
      await banAdmin.mutateAsync({
        id: admin.id,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Admin banned successfully',
  })

  if (admin.role === 'superAdmin') {
    return (
      <Segment title="Super Admins cannot be edited">
        <Alert color="red">You cannot edit a super admin</Alert>
      </Segment>
    )
  }

  if (admin.role === 'mutant') {
    return (
      <Segment title="Mutants cannot be edited">
        <Alert color="red">You cannot edit a mutant</Alert>
      </Segment>
    )
  }

  return (
    <Segment title={`Admin: ${admin.name || admin.email}`} description={admin.id}>
      {admin.bannedAt && (
        <>
          <Alert color="red">This admin is banned</Alert>
          <br />
        </>
      )}
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Name" name="name" formik={formik} />
          <RadioButtons options={availableRolesOptions} label="Role" name="role" formik={formik} />
          <Alert {...alertProps} />
          <Alert {...banForm.alertProps} />
          <Buttons>
            <Button {...buttonProps}>Update Admin</Button>
            {!admin.bannedAt && (
              <Button
                {...banForm.buttonProps}
                color="red"
                type="button"
                onClick={() => {
                  banForm.formik.handleSubmit()
                }}
              >
                Ban Admin
              </Button>
            )}
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
