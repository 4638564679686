import { zGetConversionsStatsWebTrpcInput } from '@chatai/admin-backend/src/router/conversions/getConversionsStatsWeb/input'
import { canViewConversions } from '@chatai/admin-backend/src/utils/can'
import { format, subDays } from 'date-fns'
import { saveAs } from 'file-saver'
import trim from 'lodash/trim'
import { useRef } from 'react'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button/index.tsx'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea/index.tsx'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetConversionsStatsWebTrpcInput.pick({
  dateFrom: true,
  dateTo: true,
  search: true,
  groupBy: true,
})
export const ConversionsStatsWebPage = withPageWrapper({
  title: 'Conversions Stats Web',
  checkAccess: ({ ctx }) => canViewConversions(ctx.me),
})(() => {
  const getConversionsStatsCsv = trpc.getConversionsStatsWebCsv.useMutation()
  const nowRef = useRef(new Date())
  const searchForm = useForm({
    initialValues: {
      dateFrom: format(subDays(nowRef.current, 7), 'dd.MM.yyyy'),
      dateTo: format(nowRef.current, 'dd.MM.yyyy'),
      search: '',
      groupBy: '',
    },
    validationSchema,
  })
  const csvForm = useForm({
    onSubmit: async () => {
      const { base64 } = await getConversionsStatsCsv.mutateAsync({
        dateFrom: searchForm.formik.values.dateFrom?.toString(),
        dateTo: searchForm.formik.values.dateTo?.toString(),
        search: searchForm.formik.values.search,
        groupBy: searchForm.formik.values.groupBy,
      })
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'text/csv' })
      saveAs(blob, 'conversions_stats_web.csv')
    },
  })
  const filter = (() => {
    const maybeFilter = {
      search: searchForm.formik.values.search,
      groupBy: searchForm.formik.values.groupBy,
      dateFrom: searchForm.formik.values.dateFrom,
      dateTo: searchForm.formik.values.dateTo,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return (
      parseResult.success
        ? maybeFilter
        : {
            dateFrom: format(subDays(nowRef.current, 7), 'dd.MM.yyyy'),
            dateTo: format(nowRef.current, 'dd.MM.yyyy'),
            search: '',
            groupBy: '',
          }
    ) as {
      groupBy: string
      search: string
      dateFrom: string
      dateTo: string
    }
  })()

  const qr = trpc.getConversionsStatsWeb.useQuery({
    ...filter,
  })
  const groupByFields = ['type', ...(filter.groupBy?.split(',').map(trim).filter(Boolean) || [])]

  return (
    <Segment title="Conversions Stats Web">
      <div className={css.filter}>
        <FormItems>
          <Textarea
            maxWidth={'100%'}
            label="Search Params"
            name="search"
            formik={searchForm.formik}
            initialHeight={60}
            hint={
              <>
                mediaSource=FacebookAds
                <br />
                campaignId=~Reels
              </>
            }
          />
          <Input
            maxWidth={'100%'}
            label="Group By"
            name="groupBy"
            formik={searchForm.formik}
            hint="day, mediaSource, channel, campaign, campaignId, adset, adsetId, ad, adId, siteId, keywords, countryCode"
          />
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={searchForm.formik} />
          {!qr.error && (
            <Button
              type="button"
              onClick={() => {
                csvForm.formik.handleSubmit()
              }}
            >
              Download CSV
            </Button>
          )}
        </FormItems>
      </div>
      {qr.error ? (
        <Alert color="red">{qr.error.message}</Alert>
      ) : !qr.data ? (
        <Loader type="section" />
      ) : (
        <table className={css.table}>
          <thead>
            <tr>
              <th colSpan={groupByFields.length + 9}>General</th>
              <th className={css.yellow} colSpan={4}>
                1 Month
              </th>
              <th className={css.purple} colSpan={4}>
                3 Month
              </th>
              <th className={css.yellow} colSpan={4}>
                12 Month
              </th>
            </tr>
            <tr>
              {/* <th>Group By: {filter.groupBy || 'none'}</th> */}
              {groupByFields.map((field, i) => (
                <th key={i}>Group: {field || '—'}</th>
              ))}
              <th>Install</th>
              <th>Confirm Email</th>
              <th>I→CE</th>
              <th>AD Spend</th>
              <th>Revenue</th>
              <th>Proceeds</th>
              <th>I→P</th>
              <th>R per I</th>
              <th>Cancel Subscription</th>
              <th className={css.yellow}>First Purchase</th>
              <th className={css.yellow}>Total Purchases</th>
              <th className={css.yellow}>Revenue</th>
              <th className={css.yellow}>Cancel Subscription</th>
              <th className={css.purple}>First Purchase</th>
              <th className={css.purple}>Total Purchases</th>
              <th className={css.purple}>Revenue</th>
              <th className={css.purple}>Cancel Subscription</th>
              <th className={css.yellow}>First Purchase</th>
              <th className={css.yellow}>Total Purchases</th>
              <th className={css.yellow}>Revenue</th>
              <th className={css.yellow}>Cancel Subscription</th>
            </tr>
          </thead>
          <tbody>
            {qr.data.map((item, j) => {
              return (
                <tr key={j}>
                  {/* <td>{item.groupByValue}</td> */}
                  {item.groupByValues.map((v, i) => (
                    <td key={i}>{v || '—'}</td>
                  ))}
                  <td>{item.stats.install}</td>
                  <td>{item.stats.emailConfirmed}</td>
                  <td>{item.stats.installEmailConfirmed.toFixed(2) + '%'}</td>
                  <td>{item.stats.adSpend.toFixed(2) + '$'}</td>
                  <td>{item.stats.revenue.toFixed(2) + '$'}</td>
                  <td>{item.stats.proceeds.toFixed(2) + '$'}</td>
                  <td>{item.stats.installPurchase.toFixed(2) + '%'}</td>
                  <td>{item.stats.revenuePerInstall.toFixed(2) + '$'}</td>
                  <td>{item.stats.subscriptionRenewalCancelled}</td>
                  <td className={css.yellow}>{item.stats.anyPurchase1Month}</td>
                  <td className={css.yellow}>{item.stats.totalPurchase1Month}</td>
                  <td className={css.yellow}>{item.stats.revenue1Month.toFixed(2) + '$'}</td>
                  <td className={css.yellow}>{item.stats.subscriptionRenewalCancelled1Month}</td>
                  <td className={css.purple}>{item.stats.anyPurchase3Month}</td>
                  <td className={css.purple}>{item.stats.totalPurchase3Month}</td>
                  <td className={css.purple}>{item.stats.revenue3Month.toFixed(2) + '$'}</td>
                  <td className={css.purple}>{item.stats.subscriptionRenewalCancelled3Month}</td>
                  <td className={css.yellow}>{item.stats.anyPurchase12Month}</td>
                  <td className={css.yellow}>{item.stats.totalPurchase12Month}</td>
                  <td className={css.yellow}>{item.stats.revenue12Month.toFixed(2) + '$'}</td>
                  <td className={css.yellow}>{item.stats.subscriptionRenewalCancelled12Month}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </Segment>
  )
})
