import addMinutes from 'date-fns/addMinutes'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import endOfDay from 'date-fns/endOfDay'
import formatDate from 'date-fns/format'
import isValidDate from 'date-fns/isValid'
import ruLocale from 'date-fns/locale/ru'
import parseDate from 'date-fns/parse'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import startOfDay from 'date-fns/startOfDay'
import subMinutes from 'date-fns/subMinutes'

setDefaultOptions({
  locale: ruLocale,
})
export const toUTC = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}
export { addMinutes, differenceInMinutes, endOfDay, formatDate, isValidDate, parseDate, startOfDay, subMinutes }
