import { zStringDateFromOptonal, zStringDateToOptonal } from '@chatai/shared/src/zod'
import { z } from 'zod'

export const zGetUsersTrpcInput = z.object({
  cursor: z.coerce.number().optional(),
  limit: z.number().min(1).max(100).default(10),
  serialNumber: z.string().optional(),
  email: z.string().optional(),
  subscriptionStatus: z.enum(['never', 'active', 'inactive', 'any']),
  subscriptionProvider: z.enum(['stripe', 'adapty', 'any']),
  createdAtFrom: zStringDateFromOptonal,
  createdAtTo: zStringDateToOptonal,
  subscribedAtFrom: zStringDateFromOptonal,
  subscribedAtTo: zStringDateToOptonal,
})
